export default defineNuxtPlugin(async (nuxtApp) => {
  nuxtApp.copyTool = {
    event_date: [
      { key: "client.name", title: "Mandant", type: "default" },
      { key: "event.name", title: "Veranstaltung", type: "default" },
      { key: "price_list.title", title: "Preisliste", type: "default" },
      { key: "active", title: "Aktiv", type: "bool" },
      { key: "name", title: "Name", type: "default" },
      { key: "info", title: "Beschreibung", type: "textarea" },
      { key: "max_tickets", title: "Max. Buchungen", type: "default" },
      { key: "plan_quota", title: "Online Shop Kontingent", type: "default" },
      { key: "is_online_available", title: "Online Verfügbar", type: "bool" },
      { key: "tickets", title: "Tickets", type: "event_date_products" },
      { key: "upgrades", title: "Upgrades", type: "event_date_products" },
      { key: "room_variants", title: "Räume", type: "event_date_rooms" },
    ],

  };
});
