export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/permissions`,
  async getPermissions() {
    return await $fetch(this.apiURL, {
      headers: { Authorization: useToken().value },
    });
  },
  async getRoles() {
    return await $fetch(`${this.apiURL}/roles`, {
      headers: { Authorization: useToken().value },
    });
  },
  async getRole(id) {
    return await $fetch(`${this.apiURL}/roles/${id}`, {
      headers: { Authorization: useToken().value },
    });
  },
  async saveRole(permissionGroup) {
    return await $fetch(`${this.apiURL}/roles`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...permissionGroup },
    });
  },
  async deleteRole(permissionGroup) {
    return await $fetch(`${this.apiURL}/roles`, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: permissionGroup.id },
    });
  },

});
