export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/memberships`,
  admin_token: useState("admin_token"),

  async getMembershipList(userId) {
    return await $fetch(`${this.apiURL}/${userId}`, { headers: { Authorization: useToken().value } });
  },
  async addMembership(userId, membership) {
    const request = await $fetch(`${this.apiURL}/add/${userId}`, {
      headers: { Authorization: useToken().value },
      method: "POST",
      body: {
        membershipTypeId: membership.type,
        startDate: membership.startDate,
        endDate: membership.endDate,
      },
    });
    return request;
  },
  async updateMembership(userId, membership, membershipId) {
    return await $fetch(`${this.apiURL}/edit/${userId}/${membershipId}`, {
      headers: { Authorization: useToken().value },
      method: "PATCH",
      body: {
        membershipTypeId: membership.type,
        startDate: membership.startDate,
        endDate: membership.endDate,
      },
    });
  },
  async deleteMembership(userId, membershipId) {
    console.log("deleting membership");
    return await $fetch(`${this.apiURL}/delete/${userId}/${membershipId}`, { headers: { Authorization: useToken().value }, method: "DELETE" });
  },

  async restoreMembership(userId, membershipId) {
    console.log("restoring membership");
    return await $fetch(`${this.apiURL}/restore/${userId}/${membershipId}`, { headers: { Authorization: useToken().value }, method: "PATCH" });
  },

});
