import { defineStore } from "pinia";

export const useGlobalStore = defineStore("GlobalStore", {
  state: () => {
    return {
      isSidenavCollapsed: false,
      isLoading: false,
      tabIndex: [],
      options: [],
      socketConnection: null,
    };
  },
});
