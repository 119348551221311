export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/tickets`,
  async createTicket(orderItemId) {
    return await $fetch(this.apiURL, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { order_item_id: orderItemId },
    });
  },
});
