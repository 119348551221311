import { Modal, Tooltip } from "bootstrap/dist/js/bootstrap";

export default defineNuxtPlugin(nuxtApp => ({

  provide: {
    bootstrap: {
      Modal,
      Tooltip,
    },
  },
}));
