export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/emails`,
  async sendRegisterMail(user) {
    return await $fetch(`${this.apiURL}/send-register`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { user_id: user.id },
    });
  },
  async sendPasswordMail(user) {
    return await $fetch(`${this.apiURL}/send-password`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { user_id: user.id },
    });
  },
  async sendAdminPasswordReset(email) {
    return await $fetch(`${this.apiURL}/send-admin-password-reset`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { email },
    });
  },
  async sendMail(sender, to, subject, body, type, clientId, orderId, userId) {
    return await $fetch(`${this.apiURL}/send-mail`, {
      headers: { Authorization: useToken().value },
      method: "POST",
      body: { sender, to, subject, body, type, client_id: clientId, order_id: orderId, user_id: userId },
    });
  },

});
