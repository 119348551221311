export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/vouchers`,
  async getVouchers() {
    return await $fetch(this.apiURL, { headers: { Authorization: useToken().value } });
  },
  async getVoucher(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async lock(id, lockReason) {
    return await $fetch(`${this.apiURL}/lock`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { id, locked_reason: lockReason },
    });
  },
  async openLock(id) {
    return await $fetch(`${this.apiURL}/open-lock`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { id },
    });
  },
});
