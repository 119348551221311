export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/products`,
  apiURLGroups: `${useRuntimeConfig().public.apiURL}/product-groups`,
  async getProducts() {
    return await $fetch(this.apiURL, { headers: { Authorization: useToken().value } });
  },

  async getProductsByClient(client_id) {
    return await $fetch(`${this.apiURL}/client/${client_id}`, { headers: { Authorization: useToken().value } });
  },
  async getProduct(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async saveProduct(product) {
    return await $fetch(this.apiURL, {
      method: product.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...product },
    });
  },
  async saveClient(product, client) {
    return await $fetch(`${this.apiURL}/${product.id}/save-client`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { client_id: client.id, tax_id: client.tax_id, sales_group_id: client.sales_group_id },
    });
  },
  async deleteProduct(product) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: product.id },
    });
  },
  async getProductGroups() {
    return await $fetch(this.apiURLGroups, { headers: { Authorization: useToken().value } });
  },
  async getProductGroup(id) {
    return await $fetch(`${this.apiURLGroups}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async saveProductGroup(productGroup) {
    return await $fetch(this.apiURLGroups, {
      method: productGroup.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...productGroup },
    });
  },
  async deleteProductGroup(productGroup) {
    return await $fetch(this.apiURLGroups, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: productGroup.id },
    });
  },
  async addArticle(product, articleId) {
    return await $fetch(`${this.apiURL}/${product.id}/add-article`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { article_id: articleId },
    });
  },
  async saveRequirement(product, requirement) {
    return await $fetch(`${this.apiURL}/${product.id}/save-requirement`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: requirement,
    });
  },
  async removeRequirement(product, requirement) {
    return await $fetch(`${this.apiURL}/${product.id}/remove-requirement`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { product_requirement_id: requirement.id },
    });
  },
  async createVariant(product, parentID) {
    return await $fetch(`${this.apiURL}/${product.id}/create-variant?parent_id=${parentID}`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
  async saveVariantSort(product, sort) {
    return await $fetch(`${this.apiURL}/${product.id}/save-variant-sort`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { sort },
    });
  },
  async saveDescription(product, productDescription) {
    return await $fetch(`${this.apiURL}/${product.id}/save-description`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...productDescription },
    });
  },
  async deleteDescription(product, productDescription) {
    return await $fetch(`${this.apiURL}/${product.id}/delete-description`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { id: productDescription.id },
    });
  },
  async saveArticlePrices(productArticle, prices) {
    return await $fetch(`${this.apiURL}/${productArticle.pivot.id}/save-prices`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { prices },
    });
  },
  async removeArticle(product, productArticleId) {
    return await $fetch(`${this.apiURL}/${product.id}/remove-article`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { product_article_id: productArticleId },
    });
  },
  async addCrossSellingProduct(product, productId) {
    return await $fetch(`${this.apiURL}/${product.id}/add-crossSelling-product`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { product_id: productId },
    });
  },
  async removeCrossSellingProduct(product, productId) {
    return await $fetch(`${this.apiURL}/${product.id}/remove-crossSelling-product`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { product_id: productId },
    });
  },
  async addRoom(product, roomId) {
    return await $fetch(`${this.apiURL}/${product.id}/add-room`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { room_id: roomId },
    });
  },
  async removeRoom(product, roomId) {
    return await $fetch(`${this.apiURL}/${product.id}/remove-room`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { room_id: roomId },
    });
  },
  async uploadImage(product, image) {
    const formData = new FormData();
    formData.append("image", image);
    return await $fetch(`${this.apiURL}/${product.id}/upload-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: formData,
    });
  },
  async removeImage(product) {
    return await $fetch(`${this.apiURL}/${product.id}/remove-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
  async uploadDescriptionImage(product, productDescription, image) {
    const formData = new FormData();
    formData.append("id", productDescription.id);
    formData.append("image", image);
    return await $fetch(`${this.apiURL}/${product.id}/upload-description-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: formData,
    });
  },
  async removeDescriptionImage(product, productDescription) {
    return await $fetch(`${this.apiURL}/${product.id}/remove-description-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { id: productDescription.id },
    });
  },
});
