export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/export`,
  async exportDatev(clientId, dateFrom, dateTo) {
    return await $fetch(`${this.apiURL}/export-datev`, {
      headers: { Authorization: useToken().value },
      method: "POST",
      body: { client_id: clientId, date_from: dateFrom, date_to: dateTo },
    });
  },
});
