export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/import`,
  async loadFile(file, delimiter) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("delimiter", delimiter);

    return await $fetch(this.apiURL, {
      headers: { Authorization: useToken().value },
      method: "POST",
      body: formData,
    });
  },
  async previewUsers(attributes, data) {
    return await $fetch(`${this.apiURL}/users`, {
      headers: { Authorization: useToken().value },
      method: "POST",
      body: { attributes, data },
    });
  },
  async execute(data, target) {
    return await $fetch(`${this.apiURL}/execute`, {
      headers: { Authorization: useToken().value },
      method: "POST",
      body: { data, target },
    });
  },
});
