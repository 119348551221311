export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/newsletters`,
  async getNewsletters() {
    return await $fetch(this.apiURL, { headers: { Authorization: useToken().value } });
  },
  async getNewsletter(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async getFilledOutNewsletter(id, userId) {
    return await $fetch(`${this.apiURL}/content/${id}/${userId}`, { headers: { Authorization: useToken().value } });
  },
  async getFilledOutNewsletterByOrder(id, orderId) {
    return await $fetch(`${this.apiURL}/content-order/${id}/${orderId}`, { headers: { Authorization: useToken().value } });
  },
  async saveNewsletter(newsletter) {
    return await $fetch(this.apiURL, {
      method: newsletter.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...newsletter },
    });
  },
  async deleteNewsletter(newsletter) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: newsletter.id },
    });
  },
});
