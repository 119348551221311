<template>
  <div class="bg-error">
    <div class="container">
      <div class="d-flex justify-content-center align-items-center flex-wrap">
        <img class="errorImage" src="~/assets/img/main/404.png" alt="GOP Variete Theater">
        <h2 class="w-100 text-center mt-3">
          Ooops...
        </h2>
        <div v-if="parseInt(errorData.statusCode) === 404">
          <div class="subheading">
            Die von Ihnen gewählte Adresse ist nicht vorhanden.
          </div>
          <p>
            Möglicherweise haben Sie einen veralteten Link bzw. ein altes Bookmark verwendet.
            Versuchen Sie den von Ihnen gewünschten Inhalt über unsere Startseite zu finden.
          </p>
        </div>
        <div v-else class="w-100 text-center">
          <div class="subheading">
            Es ist ein Fehler aufgetreten.
          </div>
          <p>Bitte versuchen Sie es später erneut.</p>
          <NuxtLink class="btn btn-white my-3" to="/">
            Zurück zur Startseite
          </NuxtLink>
          <div>
            <i>{{ errorData.message }}</i> <Icon @click="show = !show" v-if="errorData.stack" icon="fa-chevron-down" class="hover-2" />
            <div v-if="show">{{ errorData.stack }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const errorData = useError();
const show = ref(false);
</script>
