export default defineNuxtPlugin((nuxtApp) => {
  // Function to strip HTML tags from a string
  const stripHtmlTags = (text) => {
    // Create a temporary element to use the browser's HTML parser
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = text;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  // Inject the function into the Nuxt app context
  nuxtApp.provide("stripHtmlTags", stripHtmlTags);
});
