export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/membershipTypes`,
  admin_token: useState("admin_token"),

  async getMembershipTypes() {
    return await $fetch(this.apiURL, { headers: { Authorization: useToken().value } });
  },
  async getMembershipTypesWithDeleted() {
    return await $fetch(`${this.apiURL}/all`, { headers: { Authorization: useToken().value } });
  },
  async addMembershipType(membership) {
    return await $fetch(`${this.apiURL}/new`, { headers: { Authorization: useToken().value }, method: "POST", body: membership });
  },

  async updateMembership(membershipId, membership) {
    return await $fetch(`${this.apiURL}/${membershipId}/update`, {
      headers: { Authorization: useToken().value },
      method: "PATCH",
      body: membership,
    });
  },
  async deleteMembershipType(membershipId) {
    return await $fetch(`${this.apiURL}/${membershipId}`, { headers: { Authorization: useToken().value }, method: "DELETE" });
  },

});
