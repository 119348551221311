export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/events`,
  async getEvents() {
    return await $fetch(this.apiURL, { headers: { Authorization: useToken().value } });
  },
  async getCheckoutEvents(clientID, date) {
    return await $fetch(`${this.apiURL}/checkout?client_id=${clientID}&date=${date}`, { headers: { Authorization: useToken().value } });
  },
  async getEvent(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async saveEvent(event) {
    return await $fetch(this.apiURL, {
      method: event.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...event },
    });
  },
  async deleteEvent(event) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: event.id },
    });
  },
  async uploadImage(event, image) {
    const formData = new FormData();
    formData.append("image", image);
    return await $fetch(`${this.apiURL}/${event.id}/upload-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: formData,
    });
  },
  async removeImage(event) {
    return await $fetch(`${this.apiURL}/${event.id}/remove-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
});
