import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import HeatmapModule from "highcharts/modules/heatmap";
import DEMapData from "@highcharts/map-collection/countries/de/de-all.geo.json";
import stockInit from "highcharts/modules/stock";

export default defineNuxtPlugin((nuxtApp) => {
  // Initialisiere Highcharts Stock-Modul, wenn Highcharts verfügbar ist
  if (typeof Highcharts === "object") {
    stockInit(Highcharts);
  }
  HeatmapModule(Highcharts);

  // Registriere HighchartsVue als Plugin
  nuxtApp.vueApp.use(HighchartsVue);
  nuxtApp.provide("Highcharts", Highcharts);
  nuxtApp.provide("DEMapData", DEMapData);
});
