import DataTable from "datatables.net-vue3";
import DataTablesLib from "datatables.net";
import jszip from "jszip";
import pdfmake from "pdfmake";

import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5";

pdfmake.fonts = {
  Roboto: {
    normal: `${location.origin}/assets/fonts/Roboto-Regular.ttf`,
    bold: `${location.origin}/assets/fonts/Roboto-Medium.ttf`,
    italics: `${location.origin}/assets/fonts/Roboto-Italic.ttf`,
    bolditalics: `${location.origin}/assets/fonts/Roboto-MediumItalic.ttf`,
  },
};

DataTable.use(DataTablesLib);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfmake);

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component("FilterDataTable", DataTable);
});
