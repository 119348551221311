export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/pdf`,
  async downloadPDFPreview(client) {
    return await $fetch(`${this.apiURL}/preview/${client.id}`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
  async downloadTicketPreview(client) {
    return await $fetch(`${this.apiURL}/ticket/${client.id}`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
});
