export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/articles`,
  apiURLGroups: `${useRuntimeConfig().public.apiURL}/article-groups`,
  async getArticle(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async saveArticle(article) {
    return await $fetch(this.apiURL, {
      method: article.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...article },
    });
  },
  async deleteArticle(article) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: article.id },
    });
  },
  async getArticleGroups() {
    return await $fetch(this.apiURLGroups, { headers: { Authorization: useToken().value } });
  },
  async getArticleGroup(id) {
    return await $fetch(`${this.apiURLGroups}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async saveArticleGroup(articleGroup) {
    return await $fetch(this.apiURLGroups, {
      method: articleGroup.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...articleGroup },
    });
  },
  async deleteArticleGroup(articleGroup) {
    return await $fetch(this.apiURLGroups, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: articleGroup.id },
    });
  },
});
