export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/sales-groups`,
  async getSalesGroups() {
    return await $fetch(this.apiURL, { headers: { Authorization: useToken().value } });
  },
  async getSalesGroup(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async saveSalesGroup(salesGroup) {
    return await $fetch(this.apiURL, {
      method: salesGroup.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...salesGroup },
    });
  },
  async deleteSalesGroup(salesGroup) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: salesGroup.id },
    });
  },
  async saveSalesAccount(salesAccount) {
    return await $fetch(`${this.apiURL}/save-sales-account/${salesAccount.id}`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...salesAccount, sales_account_value: salesAccount.value },
    });
  },
  async deleteSalesAccount(salesAccount) {
    return await $fetch(`${this.apiURL}/delete-sales-account/${salesAccount.id}`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
});
