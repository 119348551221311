import { default as _91id_939sbw2rFWaSMeta } from "/var/www/nuxt3-frontend/pages/admin-groups/[id].vue?macro=true";
import { default as indexDxuiDEua64Meta } from "/var/www/nuxt3-frontend/pages/admin-groups/index.vue?macro=true";
import { default as admin_45passwordJcza0RpalcMeta } from "/var/www/nuxt3-frontend/pages/admin-password.vue?macro=true";
import { default as _91id_93IY5tW4LxhbMeta } from "/var/www/nuxt3-frontend/pages/admins/[id].vue?macro=true";
import { default as indexOShC8E9PCGMeta } from "/var/www/nuxt3-frontend/pages/admins/index.vue?macro=true";
import { default as _91id_93MBRRKpRFcfMeta } from "/var/www/nuxt3-frontend/pages/article-groups/[id].vue?macro=true";
import { default as indexYI2ml3IMEaMeta } from "/var/www/nuxt3-frontend/pages/article-groups/index.vue?macro=true";
import { default as _91id_93CPDyToySSlMeta } from "/var/www/nuxt3-frontend/pages/articles/[id].vue?macro=true";
import { default as indexIScGSEtdN8Meta } from "/var/www/nuxt3-frontend/pages/articles/index.vue?macro=true";
import { default as _91id_93z4sz85zMR8Meta } from "/var/www/nuxt3-frontend/pages/clients/[id].vue?macro=true";
import { default as indexg3kJh4J9qLMeta } from "/var/www/nuxt3-frontend/pages/clients/index.vue?macro=true";
import { default as code_45checkjaCt7591f4Meta } from "/var/www/nuxt3-frontend/pages/code-check.vue?macro=true";
import { default as _91id_93hqzJhucdw9Meta } from "/var/www/nuxt3-frontend/pages/email-template/[id].vue?macro=true";
import { default as indexhHfVf8sQW0Meta } from "/var/www/nuxt3-frontend/pages/email-template/index.vue?macro=true";
import { default as _91id_93ejQj6UJpcLMeta } from "/var/www/nuxt3-frontend/pages/event-dates/[id].vue?macro=true";
import { default as edit8K90XBUyhqMeta } from "/var/www/nuxt3-frontend/pages/event-dates/edit.vue?macro=true";
import { default as indexA5mMlljB0wMeta } from "/var/www/nuxt3-frontend/pages/event-dates/index.vue?macro=true";
import { default as _91id_93aCMCDnXT3JMeta } from "/var/www/nuxt3-frontend/pages/events/[id].vue?macro=true";
import { default as indexAMWZEtTgdMMeta } from "/var/www/nuxt3-frontend/pages/events/index.vue?macro=true";
import { default as export_45datevv4jB4KwxriMeta } from "/var/www/nuxt3-frontend/pages/export/export-datev.vue?macro=true";
import { default as _91id_93LOvamUkb2pMeta } from "/var/www/nuxt3-frontend/pages/filters/[id].vue?macro=true";
import { default as indexoagTLFmqwqMeta } from "/var/www/nuxt3-frontend/pages/filters/index.vue?macro=true";
import { default as import_45usersYq4zaesVEAMeta } from "/var/www/nuxt3-frontend/pages/import/import-users.vue?macro=true";
import { default as indexlYLn7Ou1byMeta } from "/var/www/nuxt3-frontend/pages/index.vue?macro=true";
import { default as logint6V8U6atOHMeta } from "/var/www/nuxt3-frontend/pages/login.vue?macro=true";
import { default as indexDjUMdRSRUkMeta } from "/var/www/nuxt3-frontend/pages/membership-types/index.vue?macro=true";
import { default as _91id_9374M2Jp7nWxMeta } from "/var/www/nuxt3-frontend/pages/newsletters/[id].vue?macro=true";
import { default as indexn5UcPX27MfMeta } from "/var/www/nuxt3-frontend/pages/newsletters/index.vue?macro=true";
import { default as index0Pvt2XOW6XMeta } from "/var/www/nuxt3-frontend/pages/options/index.vue?macro=true";
import { default as _91id_93K9D5vSp3AXMeta } from "/var/www/nuxt3-frontend/pages/orders/[id].vue?macro=true";
import { default as indexLn4rqs6D2jMeta } from "/var/www/nuxt3-frontend/pages/orders/index.vue?macro=true";
import { default as password_45resetzi9lpTcxx2Meta } from "/var/www/nuxt3-frontend/pages/password-reset.vue?macro=true";
import { default as _91id_933uK6qfjMOwMeta } from "/var/www/nuxt3-frontend/pages/payments/[id].vue?macro=true";
import { default as indexLJiIz8Mfo6Meta } from "/var/www/nuxt3-frontend/pages/payments/index.vue?macro=true";
import { default as _91id_937rXT30KHS7Meta } from "/var/www/nuxt3-frontend/pages/plan-variants/[id].vue?macro=true";
import { default as _91id_93bc8kF9QVgFMeta } from "/var/www/nuxt3-frontend/pages/plans/[id].vue?macro=true";
import { default as index06YBjEOFZPMeta } from "/var/www/nuxt3-frontend/pages/plans/index.vue?macro=true";
import { default as index1mqr4Z0Fm1Meta } from "/var/www/nuxt3-frontend/pages/prices/index.vue?macro=true";
import { default as _91id_93GHH55855vtMeta } from "/var/www/nuxt3-frontend/pages/product-groups/[id].vue?macro=true";
import { default as indexRlN5BPyiqLMeta } from "/var/www/nuxt3-frontend/pages/product-groups/index.vue?macro=true";
import { default as _91id_939rJWLMlTpuMeta } from "/var/www/nuxt3-frontend/pages/products/[id].vue?macro=true";
import { default as indexGJdc2qrVZDMeta } from "/var/www/nuxt3-frontend/pages/products/index.vue?macro=true";
import { default as _91id_93rIqxum4p69Meta } from "/var/www/nuxt3-frontend/pages/promotion-groups/[id].vue?macro=true";
import { default as indexcE7pKAWYv5Meta } from "/var/www/nuxt3-frontend/pages/promotion-groups/index.vue?macro=true";
import { default as _91id_9347QXAPSM3mMeta } from "/var/www/nuxt3-frontend/pages/promotions/[id].vue?macro=true";
import { default as indexmf9iBDnbJ1Meta } from "/var/www/nuxt3-frontend/pages/promotions/index.vue?macro=true";
import { default as _91id_93MAEpzILTOXMeta } from "/var/www/nuxt3-frontend/pages/roles/[id].vue?macro=true";
import { default as indexh7l8y00CFjMeta } from "/var/www/nuxt3-frontend/pages/roles/index.vue?macro=true";
import { default as _91id_93zDIejB6aJjMeta } from "/var/www/nuxt3-frontend/pages/rooms/[id].vue?macro=true";
import { default as indexIEw50RIfUaMeta } from "/var/www/nuxt3-frontend/pages/rooms/index.vue?macro=true";
import { default as _91id_93dFtMYnYihDMeta } from "/var/www/nuxt3-frontend/pages/sales-groups/[id].vue?macro=true";
import { default as indexLMZnB4uifkMeta } from "/var/www/nuxt3-frontend/pages/sales-groups/index.vue?macro=true";
import { default as _91id_93IsgyNVGVXnMeta } from "/var/www/nuxt3-frontend/pages/shipping-methods/[id].vue?macro=true";
import { default as indexn8pe0OBDAuMeta } from "/var/www/nuxt3-frontend/pages/shipping-methods/index.vue?macro=true";
import { default as _91id_93A7rSg3dzizMeta } from "/var/www/nuxt3-frontend/pages/shops/[id].vue?macro=true";
import { default as indexuHXFCauDQrMeta } from "/var/www/nuxt3-frontend/pages/shops/index.vue?macro=true";
import { default as indexLmMVHDgnmYMeta } from "/var/www/nuxt3-frontend/pages/skategorie/index.vue?macro=true";
import { default as _91id_93uMirFNFPa2Meta } from "/var/www/nuxt3-frontend/pages/support/[id].vue?macro=true";
import { default as indexUOHLni20L0Meta } from "/var/www/nuxt3-frontend/pages/support/index.vue?macro=true";
import { default as _91id_93XwFhP28Bv3Meta } from "/var/www/nuxt3-frontend/pages/surveys/[id].vue?macro=true";
import { default as indexrvNI6uveSJMeta } from "/var/www/nuxt3-frontend/pages/surveys/index.vue?macro=true";
import { default as _91id_93WyStELkKooMeta } from "/var/www/nuxt3-frontend/pages/tasks/[id].vue?macro=true";
import { default as indexQgZyG99sf5Meta } from "/var/www/nuxt3-frontend/pages/tasks/index.vue?macro=true";
import { default as _91id_93TeFCji4Co3Meta } from "/var/www/nuxt3-frontend/pages/taxes/[id].vue?macro=true";
import { default as indext9IlTSNoglMeta } from "/var/www/nuxt3-frontend/pages/taxes/index.vue?macro=true";
import { default as testsyuLWCO5fHMeta } from "/var/www/nuxt3-frontend/pages/test.vue?macro=true";
import { default as _91id_93F3KkCM3lwcMeta } from "/var/www/nuxt3-frontend/pages/user-groups/[id].vue?macro=true";
import { default as indexusqEekWVNFMeta } from "/var/www/nuxt3-frontend/pages/user-groups/index.vue?macro=true";
import { default as _91id_93NZOGRDHdF6Meta } from "/var/www/nuxt3-frontend/pages/users/[id].vue?macro=true";
import { default as indextyXPMKGnR4Meta } from "/var/www/nuxt3-frontend/pages/users/index.vue?macro=true";
import { default as index7Qx5MLa0jtMeta } from "/var/www/nuxt3-frontend/pages/utilization-dashboard/index.vue?macro=true";
import { default as _91id_93BwXYYfrIm9Meta } from "/var/www/nuxt3-frontend/pages/vouchers/[id].vue?macro=true";
import { default as indexaSiHcC4pN2Meta } from "/var/www/nuxt3-frontend/pages/vouchers/index.vue?macro=true";
export default [
  {
    name: "admin-groups-id",
    path: "/admin-groups/:id()",
    meta: _91id_939sbw2rFWaSMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/admin-groups/[id].vue")
  },
  {
    name: "admin-groups",
    path: "/admin-groups",
    meta: indexDxuiDEua64Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/admin-groups/index.vue")
  },
  {
    name: "admin-password",
    path: "/admin-password",
    meta: admin_45passwordJcza0RpalcMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/admin-password.vue")
  },
  {
    name: "admins-id",
    path: "/admins/:id()",
    meta: _91id_93IY5tW4LxhbMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/admins/[id].vue")
  },
  {
    name: "admins",
    path: "/admins",
    meta: indexOShC8E9PCGMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/admins/index.vue")
  },
  {
    name: "article-groups-id",
    path: "/article-groups/:id()",
    meta: _91id_93MBRRKpRFcfMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/article-groups/[id].vue")
  },
  {
    name: "article-groups",
    path: "/article-groups",
    meta: indexYI2ml3IMEaMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/article-groups/index.vue")
  },
  {
    name: "articles-id",
    path: "/articles/:id()",
    meta: _91id_93CPDyToySSlMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/articles/[id].vue")
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexIScGSEtdN8Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/articles/index.vue")
  },
  {
    name: "clients-id",
    path: "/clients/:id()",
    meta: _91id_93z4sz85zMR8Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/clients/[id].vue")
  },
  {
    name: "clients",
    path: "/clients",
    meta: indexg3kJh4J9qLMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/clients/index.vue")
  },
  {
    name: "code-check",
    path: "/code-check",
    meta: code_45checkjaCt7591f4Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/code-check.vue")
  },
  {
    name: "email-template-id",
    path: "/email-template/:id()",
    meta: _91id_93hqzJhucdw9Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/email-template/[id].vue")
  },
  {
    name: "email-template",
    path: "/email-template",
    meta: indexhHfVf8sQW0Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/email-template/index.vue")
  },
  {
    name: "event-dates-id",
    path: "/event-dates/:id()",
    meta: _91id_93ejQj6UJpcLMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/event-dates/[id].vue")
  },
  {
    name: "event-dates-edit",
    path: "/event-dates/edit",
    meta: edit8K90XBUyhqMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/event-dates/edit.vue")
  },
  {
    name: "event-dates",
    path: "/event-dates",
    meta: indexA5mMlljB0wMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/event-dates/index.vue")
  },
  {
    name: "events-id",
    path: "/events/:id()",
    meta: _91id_93aCMCDnXT3JMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/events/[id].vue")
  },
  {
    name: "events",
    path: "/events",
    meta: indexAMWZEtTgdMMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/events/index.vue")
  },
  {
    name: "export-export-datev",
    path: "/export/export-datev",
    meta: export_45datevv4jB4KwxriMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/export/export-datev.vue")
  },
  {
    name: "filters-id",
    path: "/filters/:id()",
    meta: _91id_93LOvamUkb2pMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/filters/[id].vue")
  },
  {
    name: "filters",
    path: "/filters",
    meta: indexoagTLFmqwqMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/filters/index.vue")
  },
  {
    name: "import-import-users",
    path: "/import/import-users",
    meta: import_45usersYq4zaesVEAMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/import/import-users.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexlYLn7Ou1byMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logint6V8U6atOHMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/login.vue")
  },
  {
    name: "membership-types",
    path: "/membership-types",
    component: () => import("/var/www/nuxt3-frontend/pages/membership-types/index.vue")
  },
  {
    name: "newsletters-id",
    path: "/newsletters/:id()",
    meta: _91id_9374M2Jp7nWxMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/newsletters/[id].vue")
  },
  {
    name: "newsletters",
    path: "/newsletters",
    meta: indexn5UcPX27MfMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/newsletters/index.vue")
  },
  {
    name: "options",
    path: "/options",
    meta: index0Pvt2XOW6XMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/options/index.vue")
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    meta: _91id_93K9D5vSp3AXMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/orders/[id].vue")
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexLn4rqs6D2jMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/orders/index.vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: password_45resetzi9lpTcxx2Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/password-reset.vue")
  },
  {
    name: "payments-id",
    path: "/payments/:id()",
    meta: _91id_933uK6qfjMOwMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/payments/[id].vue")
  },
  {
    name: "payments",
    path: "/payments",
    meta: indexLJiIz8Mfo6Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/payments/index.vue")
  },
  {
    name: "plan-variants-id",
    path: "/plan-variants/:id()",
    meta: _91id_937rXT30KHS7Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/plan-variants/[id].vue")
  },
  {
    name: "plans-id",
    path: "/plans/:id()",
    meta: _91id_93bc8kF9QVgFMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/plans/[id].vue")
  },
  {
    name: "plans",
    path: "/plans",
    meta: index06YBjEOFZPMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/plans/index.vue")
  },
  {
    name: "prices",
    path: "/prices",
    meta: index1mqr4Z0Fm1Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/prices/index.vue")
  },
  {
    name: "product-groups-id",
    path: "/product-groups/:id()",
    meta: _91id_93GHH55855vtMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/product-groups/[id].vue")
  },
  {
    name: "product-groups",
    path: "/product-groups",
    meta: indexRlN5BPyiqLMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/product-groups/index.vue")
  },
  {
    name: "products-id",
    path: "/products/:id()",
    meta: _91id_939rJWLMlTpuMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/products/[id].vue")
  },
  {
    name: "products",
    path: "/products",
    meta: indexGJdc2qrVZDMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/products/index.vue")
  },
  {
    name: "promotion-groups-id",
    path: "/promotion-groups/:id()",
    meta: _91id_93rIqxum4p69Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/promotion-groups/[id].vue")
  },
  {
    name: "promotion-groups",
    path: "/promotion-groups",
    meta: indexcE7pKAWYv5Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/promotion-groups/index.vue")
  },
  {
    name: "promotions-id",
    path: "/promotions/:id()",
    meta: _91id_9347QXAPSM3mMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/promotions/[id].vue")
  },
  {
    name: "promotions",
    path: "/promotions",
    meta: indexmf9iBDnbJ1Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/promotions/index.vue")
  },
  {
    name: "roles-id",
    path: "/roles/:id()",
    meta: _91id_93MAEpzILTOXMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/roles/[id].vue")
  },
  {
    name: "roles",
    path: "/roles",
    meta: indexh7l8y00CFjMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/roles/index.vue")
  },
  {
    name: "rooms-id",
    path: "/rooms/:id()",
    meta: _91id_93zDIejB6aJjMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/rooms/[id].vue")
  },
  {
    name: "rooms",
    path: "/rooms",
    meta: indexIEw50RIfUaMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/rooms/index.vue")
  },
  {
    name: "sales-groups-id",
    path: "/sales-groups/:id()",
    meta: _91id_93dFtMYnYihDMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/sales-groups/[id].vue")
  },
  {
    name: "sales-groups",
    path: "/sales-groups",
    meta: indexLMZnB4uifkMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/sales-groups/index.vue")
  },
  {
    name: "shipping-methods-id",
    path: "/shipping-methods/:id()",
    meta: _91id_93IsgyNVGVXnMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/shipping-methods/[id].vue")
  },
  {
    name: "shipping-methods",
    path: "/shipping-methods",
    meta: indexn8pe0OBDAuMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/shipping-methods/index.vue")
  },
  {
    name: "shops-id",
    path: "/shops/:id()",
    meta: _91id_93A7rSg3dzizMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/shops/[id].vue")
  },
  {
    name: "shops",
    path: "/shops",
    meta: indexuHXFCauDQrMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/shops/index.vue")
  },
  {
    name: "skategorie",
    path: "/skategorie",
    meta: indexLmMVHDgnmYMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/skategorie/index.vue")
  },
  {
    name: "support-id",
    path: "/support/:id()",
    meta: _91id_93uMirFNFPa2Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/support/[id].vue")
  },
  {
    name: "support",
    path: "/support",
    meta: indexUOHLni20L0Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/support/index.vue")
  },
  {
    name: "surveys-id",
    path: "/surveys/:id()",
    meta: _91id_93XwFhP28Bv3Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/surveys/[id].vue")
  },
  {
    name: "surveys",
    path: "/surveys",
    meta: indexrvNI6uveSJMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/surveys/index.vue")
  },
  {
    name: "tasks-id",
    path: "/tasks/:id()",
    meta: _91id_93WyStELkKooMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/tasks/[id].vue")
  },
  {
    name: "tasks",
    path: "/tasks",
    meta: indexQgZyG99sf5Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/tasks/index.vue")
  },
  {
    name: "taxes-id",
    path: "/taxes/:id()",
    meta: _91id_93TeFCji4Co3Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/taxes/[id].vue")
  },
  {
    name: "taxes",
    path: "/taxes",
    meta: indext9IlTSNoglMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/taxes/index.vue")
  },
  {
    name: "test",
    path: "/test",
    meta: testsyuLWCO5fHMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/test.vue")
  },
  {
    name: "user-groups-id",
    path: "/user-groups/:id()",
    meta: _91id_93F3KkCM3lwcMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/user-groups/[id].vue")
  },
  {
    name: "user-groups",
    path: "/user-groups",
    meta: indexusqEekWVNFMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/user-groups/index.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: _91id_93NZOGRDHdF6Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/users/[id].vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indextyXPMKGnR4Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/users/index.vue")
  },
  {
    name: "utilization-dashboard",
    path: "/utilization-dashboard",
    meta: index7Qx5MLa0jtMeta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/utilization-dashboard/index.vue")
  },
  {
    name: "vouchers-id",
    path: "/vouchers/:id()",
    meta: _91id_93BwXYYfrIm9Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/vouchers/[id].vue")
  },
  {
    name: "vouchers",
    path: "/vouchers",
    meta: indexaSiHcC4pN2Meta || {},
    component: () => import("/var/www/nuxt3-frontend/pages/vouchers/index.vue")
  }
]