export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/support`,
  admin_token: useState("admin_token"),

  async getTicketInfos(ticketId) {
    return await $fetch(`${this.apiURL}/ticket/${ticketId}`, { headers: { Authorization: useToken().value } });
  },
  async getNextUpdate() {    
    return await $fetch(`${this.apiURL}/nextupdate`, { headers: { Authorization: useToken().value } });
  },

  async saveComment(body) {
    return await $fetch(`${this.apiURL}/ticket/${body.support_ticket_id}/update`, {
      headers: { Authorization: useToken().value },
      method: "post",
      body,
    });
  },

  async modifyContent(body) {
    return await $fetch(`${this.apiURL}/ticket/ai`, {
      headers: { Authorization: useToken().value },
      method: "post",
      body,
    });
  },
   
  async getEnums() {
    return await $fetch(`${this.apiURL}/enums`, { headers: { Authorization: useToken().value } });
  },

  async getAttachement(id) {
    return await $fetch(`${this.apiURL}/attachement/${id}`, { headers: { Authorization: useToken().value } });
  },
  async isClientValid(id) {
    return await $fetch(`${this.apiURL}/clientValid/${id}`, { headers: { Authorization: useToken().value } });
  },
  async deleteCategory(id) {
    return await $fetch(`${this.apiURL}/category/delete/${id}`, {
      method: "delete",
    });
  },
  async saveTemplate(body) {
    return await $fetch(`${this.apiURL}/emailTemplates`, {
      headers: { Authorization: useToken().value },
      method: "post",
      body,
    });
  },
  async deleteTemplate(id) {
    return await $fetch(`${this.apiURL}/emailTemplates/delete/${id}`, {
      method: "delete",
    });
  },


  async updateTicketCategory(ticketId, body) {
    return await $fetch(`${this.apiURL}/ticket/${ticketId}/update/category`, {
      method: "patch",
      body,
    });
  },

  async updateTicketEnum(ticketId, column, value, authorId) {
    return await $fetch(`${this.apiURL}/ticket/${ticketId}/status`, {
      headers: { Authorization: useToken().value },
      method: "patch",
      body: {
        column,
        value,
        author_id: authorId,
      },
    });
  },
  async getTemplate(id) {
    return await $fetch(`${this.apiURL}/emailTemplate/${id}`, { headers: { Authorization: useToken().value } });
  },
  async getTemplates() {
    return await $fetch(`${this.apiURL}/emailTemplates`, { headers: { Authorization: useToken().value } });
  },

  async createSupportCategory(body) {
    return await $fetch(`${this.apiURL}/category/new`, {
      headers: { Authorization: useToken().value },
      method: "post",
      body,
    });
  },
  async updateSupportCategory(categoryId, body) {
    return await $fetch(`${this.apiURL}/category/update/${categoryId}`, {
      headers: { Authorization: useToken().value },
      method: "patch",
      body,
    });
  },

  async getSupportCategoryData() {
    return await $fetch(`${this.apiURL}/categories`, { headers: { Authorization: useToken().value } });
  },

});
