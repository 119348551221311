import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { useGlobalStore } from "~/stores/GlobalStore";

dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);

export default defineNuxtPlugin(async (nuxtApp) => {
  nuxtApp.runtimeConfig = useRuntimeConfig();
  nuxtApp.globalStore = useGlobalStore();
  nuxtApp.router = useRouter();
  nuxtApp.functions = {
    getPriceOfProduct: (product, priceListId, discountLevelId, priceCategoryId) => {
      let price = "";
      product.articles.forEach((article) => {
        article.prices.forEach((_price) => {
          if (_price.price_category_id === priceCategoryId && _price.discount_level_id === discountLevelId && _price.price_list_id === priceListId) {
            if (price === "") price = 0;
            price += _price.price;
          }
        });
      });

      return price;
    },
    getPriceOfArticle: (article, priceListId, discountLevelId, priceCategoryId) => {
      let price = "";
      article.prices.forEach((_price) => {
        if (_price.price_category_id === priceCategoryId && _price.discount_level_id === discountLevelId && _price.price_list_id === priceListId) price = _price.price;
      });

      return price;
    },
  };
  nuxtApp.format = {
    dateFormat: (date, format) => {
      if (!date) return date;
      if (!format) format = "DD.MM.YY";

      return dayjs(date).format(format);
    },
    floatFormat: (number) => {
      return Number.parseFloat(number).toFixed(2);
    },
    currencyFormat: (number) => {
      if (!number && number !== 0) return number;
      const EuroFormat = Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" });
      return EuroFormat.format(number);
    },
    longTextSlice: (text, length) => {
      if (!length) length = 120;
      return (text.length > length) ? `${text.slice(0, length - 1)}&hellip;` : text;
    },
  };
});
