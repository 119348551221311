export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/shipping-methods`,
  async getShippingMethods() {
    return await $fetch(`${this.apiURL}`, { headers: { Authorization: useToken().value } });
  },
  async getShippingMethod(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async saveShippingMethod(shippingMethod) {
    return await $fetch(this.apiURL, {
      method: shippingMethod.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...shippingMethod },
    });
  },
  async saveShippingMethodsOrder(order) {
    return await $fetch(`${this.apiURL}/save-order`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { order },
    });
  },
  async deleteShippingMethod(shippingMethod) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: shippingMethod.id },
    });
  },
});
