export default defineNuxtPlugin((nuxtApp) => {
  const { signOut } = useAuth();

  nuxtApp.vueApp.config.errorHandler = (error) => {
    console.log("Error: ", error);
    if (error.response.status === 401) {
      nuxtApp.globalStore.isLoading = false;
      signOut({ callbackUrl: "/login" });
    }
  };

  nuxtApp.hook("vue:error", (error, instance, info) => {
    console.log("Error: ", error);
    if (error.response.status === 401) {
      nuxtApp.globalStore.isLoading = false;
      signOut({ callbackUrl: "/login" });
    }
  });
});
