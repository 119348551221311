import { io } from "socket.io-client";

export default defineNuxtPlugin(async (nuxtApp) => {
  if (process.client && false) {
    const runtimeConfig = useRuntimeConfig();
    const adminToken = useCookie("admin_token");

    const socket = io(runtimeConfig.socketURL, {
      auth: {
        type: "admin",
        token: useToken().value,
      },
    });

    socket.on("hello from server", () => {
      console.log("HELLO SERVER");
    });

    socket.on("connect", () => {
      console.log("CONNECT");
    });

    socket.on("connect_error", () => {

    });

    socket.on("disconnect", () => {

    });

    nuxtApp.socket = socket;
  }
});
