export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/plans`,
  apiVariantsURL: `${useRuntimeConfig().public.apiURL}/plan-variants`,
  async getPlans(clientID) {
    return await $fetch(`${this.apiURL}?client_id=${clientID}`, { headers: { Authorization: useToken().value } });
  },
  async getPlan(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async getPlanVariant(id, eventDateId) {
    return await $fetch(`${this.apiVariantsURL}/${id}?event_date_id=${eventDateId}`, { headers: { Authorization: useToken().value } });
  },
  async getPlanObjects(offset, length, search) {
    return await $fetch(`${this.apiVariantsURL}/objects?offset=${offset}&length=${length}&search=${search}`, { headers: { Authorization: useToken().value } });
  },
  async savePlanObject(planObject) {
    return await $fetch(`${this.apiVariantsURL}/save-object`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...planObject },
    });
  },
  async deletePlanObject(planObject) {
    return await $fetch(`${this.apiVariantsURL}/delete-object`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { id: planObject.id },
    });
  },
  async saveCanvas(plan) {
    return await $fetch(`${this.apiVariantsURL}/save-canvas`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...plan },
    });
  },
  async savePlan(plan) {
    return await $fetch(this.apiURL, {
      method: plan.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...plan },
    });
  },
  async savePlanVariant(planVariant) {
    return await $fetch(this.apiVariantsURL, {
      method: planVariant.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...planVariant },
    });
  },
  async deletePlan(plan) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: plan.id },
    });
  },
  async deletePlanVariant(planVariant) {
    return await $fetch(this.apiVariantsURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: planVariant.id },
    });
  },
  async uploadImage(planVariant, image) {
    const formData = new FormData();
    formData.append("image", image);
    return await $fetch(`${this.apiVariantsURL}/${planVariant.id}/upload-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: formData,
    });
  },
  async uploadSeatImage(image) {
    const formData = new FormData();
    formData.append("image", image);
    return await $fetch(`${this.apiVariantsURL}/upload-seat-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: formData,
    });
  },
  async removeImage(planVariant) {
    return await $fetch(`${this.apiVariantsURL}/${planVariant.id}/remove-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
});
