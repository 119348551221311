export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/rooms`,
  async getRoom(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async saveRoom(room) {
    return await $fetch(this.apiURL, {
      method: room.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...room },
    });
  },
  async deleteRoom(room) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: room.id },
    });
  },
  async saveRoomVariant(roomVariant) {
    return await $fetch(`${this.apiURL}/save-room-variant/${roomVariant.id}`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...roomVariant },
    });
  },
  async deleteRoomVariant(roomVariant) {
    return await $fetch(`${this.apiURL}/delete-room-variant/${roomVariant.id}`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
});
