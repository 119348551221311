export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/promotions`,
  apiURLGroups: `${useRuntimeConfig().public.apiURL}/promotion-groups`,
  apiURLCodes: `${useRuntimeConfig().public.apiURL}/promotion-codes`,
  async getPromotions() {
    return await $fetch(this.apiURL, { headers: { Authorization: useToken().value } });
  },
  async getPromotion(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async getPromotionCode(code) {
    return await $fetch(`${this.apiURL}/get-code/${code}`, { headers: { Authorization: useToken().value } });
  },
  async savePromotion(promotion) {
    return await $fetch(this.apiURL, {
      method: promotion.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...promotion },
    });
  },
  async deletePromotion(promotion) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: promotion.id },
    });
  },
  async getPromotionGroups() {
    return await $fetch(this.apiURLGroups, { headers: { Authorization: useToken().value } });
  },
  async getPromotionGroup(id) {
    return await $fetch(`${this.apiURLGroups}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async savePromotionGroup(promotionGroup) {
    return await $fetch(this.apiURLGroups, {
      method: promotionGroup.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...promotionGroup },
    });
  },
  async deletePromotionGroup(promotionGroup) {
    return await $fetch(this.apiURLGroups, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: promotionGroup.id },
    });
  },
  async uploadImage(promotion, image) {
    const formData = new FormData();
    formData.append("image", image);
    return await $fetch(`${this.apiURL}/${promotion.id}/upload-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: formData,
    });
  },
  async removeImage(promotion) {
    return await $fetch(`${this.apiURL}/${promotion.id}/remove-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
  async addPromotionCode(promotion, code) {
    return await $fetch(`${this.apiURL}/${promotion.id}/add-code`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { code },
    });
  },
  async addRandomPromotionCodes(promotion, amount) {
    return await $fetch(`${this.apiURL}/${promotion.id}/add-random-codes`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { amount },
    });
  },
  async removePromotionCode(promotion, id) {
    return await $fetch(`${this.apiURL}/${promotion.id}/remove-code`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { id },
    });
  },
  async importCodes(promotion, image) {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("image", image);
    return await $fetch(`${this.apiURL}/${promotion.id}/import-codes`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: formData,
    });
  },
  async addFilter(promotion, filterId, type) {
    return await $fetch(`${this.apiURL}/${promotion.id}/add-filter`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { filter_id: filterId, type },
    });
  },
  async getPromotionsForOrderItems(orderItems) {
    return await $fetch(`${this.apiURL}/order-items`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { order_item_ids: orderItems },
    });
  },
  async getPromotionsForOrder(order) {
    return await $fetch(`${this.apiURL}/order`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { order_id: order.id },
    });
  },
  async saveRequirement(promotion, requirement) {
    return await $fetch(`${this.apiURL}/${promotion.id}/save-requirement`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: requirement,
    });
  },
  async removeRequirement(promotion, requirement) {
    return await $fetch(`${this.apiURL}/${promotion.id}/remove-requirement`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { promotion_requirement_id: requirement.id },
    });
  },
});
