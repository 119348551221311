export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/taxes`,
  async getTaxes() {
    return await $fetch(this.apiURL, { headers: { Authorization: useToken().value } });
  },
  async getTax(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async getTSE() {
    return await $fetch(`${this.apiURL}/tse`, { headers: { Authorization: useToken().value } });
  },
  async saveTax(tax) {
    return await $fetch(this.apiURL, {
      method: tax.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...tax },
    });
  },
  async deleteTax(tax) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: tax.id },
    });
  },
  async saveTaxValue(taxValue) {
    return await $fetch(`${this.apiURL}/save-tax-value/${taxValue.id}`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...taxValue },
    });
  },
  async deleteTaxValue(taxValue) {
    return await $fetch(`${this.apiURL}/delete-tax-value/${taxValue.id}`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
});
