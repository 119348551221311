export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/charts`,
  async getOrderData(startDate, endDate, clientIds, shopIds, productGroupIds, promotionsString, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    return await $fetch(`${this.apiURL}/order?start_date=${startDate}&end_date=${endDate}&client_ids=${clientIds}&shop_ids=${shopIds}&product_group_ids=${productGroupIds}&promotions=${promotionsString}&comparisonType=${comparisonType}&comparison_start_date=${comparisonStartDate || ""}&comparison_end_date=${comparisonEndDate || ""}&comparison_client_ids=${comparisonClientIds || ""}&comparison_shop_ids=${comparisonShopIds || ""}`, { headers: { Authorization: useToken().value } });
  },
  async getUserData(startDate, endDate) {
    return await $fetch(`${this.apiURL}/user?start_date=${startDate}&end_date=${endDate}`, { headers: { Authorization: useToken().value } });
  },
  async getOrderDataByEventDate(selectedEvent, selectedPriceCategory, startDate, endDate, clientIds, shopIds, promoTicket, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    return await $fetch(`${this.apiURL}/order-by-event-date?selected_event_ids=${selectedEvent}&selected_price_category_ids=${selectedPriceCategory}&start_date=${startDate}&end_date=${endDate}&client_ids=${clientIds}&shop_id=${shopIds}&promo_ticket=${promoTicket}&comparisonType=${comparisonType}&comparison_start_date=${comparisonStartDate || ""}&comparison_end_date=${comparisonEndDate || ""}&comparison_client_ids=${comparisonClientIds || ""}&comparison_shop_ids=${comparisonShopIds || ""}`, { headers: { Authorization: useToken().value } });
  },
});
