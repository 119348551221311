import Client from "~/repositories/client";
import User from "~/repositories/user";
import Admin from "~/repositories/admin";
import Article from "~/repositories/article";
import Chat from "~/repositories/chat";
import Permission from "~/repositories/permission";
import Price from "~/repositories/price";
import Room from "~/repositories/room";
import SalesGroup from "~/repositories/sales_group";
import Settings from "~/repositories/settings";
import Tax from "~/repositories/tax";
import Product from "~/repositories/product";
import Event from "~/repositories/event";
import EventDate from "~/repositories/eventDate";
import Payment from "~/repositories/payment";
import ShippingMethod from "~/repositories/shipping_method";
import Shop from "~/repositories/shop";
import Filter from "~/repositories/filter";
import Order from "~/repositories/order";
import Email from "~/repositories/email";
import Pdf from "~/repositories/pdf";
import Promotion from "~/repositories/promotion";
import Plan from "~/repositories/plan";
import Voucher from "~/repositories/voucher";
import Export from "~/repositories/export";
import Import from "~/repositories/import";
import Membership from "~/repositories/membership";
import MembershipType from "~/repositories/membershipType";
import Ticket from "~/repositories/ticket";
import Chart from "~/repositories/chart";
import Survey from "~/repositories/survey";
import Newsletter from "~/repositories/newsletter";
import Task from "~/repositories/task";
import Activity from "~/repositories/activity";
import Support from "~/repositories/support";
import TinyMCE from "~/repositories/tinyMCE";
import UserActivity from "~/repositories/useractivity";


export default () => ({
  client: Client(),
  user: User(),
  admin: Admin(),
  article: Article(),
  chat: Chat(),
  permission: Permission(),
  room: Room(),
  price: Price(),
  sales_group: SalesGroup(),
  settings: Settings(),
  tax: Tax(),
  product: Product(),
  event: Event(),
  eventDate: EventDate(),
  payment: Payment(),
  shipping_method: ShippingMethod(),
  shop: Shop(),
  filter: Filter(),
  order: Order(),
  email: Email(),
  pdf: Pdf(),
  promotion: Promotion(),
  plan: Plan(),
  voucher: Voucher(),
  export: Export(),
  import: Import(),
  membership: Membership(),
  membershipType: MembershipType(),
  ticket: Ticket(),
  chart: Chart(),
  survey: Survey(),
  newsletter: Newsletter(),
  task: Task(),
  activity: Activity(),
  useractivity: UserActivity(),
  support: Support(),
  tinymce : TinyMCE(),
});
