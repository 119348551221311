import Swal from "sweetalert2";

export default defineNuxtPlugin(async (nuxtApp) => {
  const Toast = Swal.mixin({
    buttonsStyling: false,
    showCloseButton: true,
    confirmButtonText: "Okay",
    cancelButtonText: "Abbrechen",
    reverseButtons: true,
    customClass: {
      confirmButton: "btn btn-primary max-width-200",
      cancelButton: "btn btn-outline-secondary max-width-200",
    },
  });

  nuxtApp.toast = {
    async showError(title, info) {
      await Toast.fire({
        title,
        html: info,
      });
    },
    async showConfirmation(title, info) {
      return await Toast.fire({
        title,
        html: info,
        confirmButtonText: "<i class='fa fa-check me-1'></i> Ja",
        cancelButtonText: "<i class='fa fa-times me-1'></i> Nein",
        showCancelButton: true,
      });
    },
    showSuccess(info) {
      Toast.fire({
        icon: "success",
        html: info,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    },
  };
});
