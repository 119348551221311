export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/prices`,
  async getPriceLists() {
    const clientId = useCookie("client");
    return await $fetch(`${this.apiURL}/price-lists?client_id=${clientId.value}`, { headers: { Authorization: useToken().value } });
  },
  async savePriceList(priceList) {
    return await $fetch(`${this.apiURL}/price-lists`, {
      method: priceList.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...priceList },
    });
  },
  async deletePriceList(priceList) {
    return await $fetch(`${this.apiURL}/price-lists`, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: priceList.id },
    });
  },
  async getDiscountLevels() {
    const clientId = useCookie("client");
    return await $fetch(`${this.apiURL}/discount-levels?client_id=${clientId.value}`, { headers: { Authorization: useToken().value } });
  },
  async saveDiscountLevel(discountLevel) {
    return await $fetch(`${this.apiURL}/discount-levels`, {
      method: discountLevel.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...discountLevel },
    });
  },
  async deleteDiscountLevel(discountLevel) {
    return await $fetch(`${this.apiURL}/discount-levels`, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: discountLevel.id },
    });
  },
  async getPriceCategories() {
    const clientId = useCookie("client");
    return await $fetch(`${this.apiURL}/price-categories?client_id=${clientId.value}`, { headers: { Authorization: useToken().value } });
  },
  async savePriceCategory(priceCategory) {
    return await $fetch(`${this.apiURL}/price-categories`, {
      method: priceCategory.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...priceCategory },
    });
  },
  async deletePriceCategory(priceCategory) {
    return await $fetch(`${this.apiURL}/price-categories`, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: priceCategory.id },
    });
  },
  async saveSort(type, sort) {
    return await $fetch(`${this.apiURL}/save-sort`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { type, sort },
    });
  },
});
