import { defineNuxtPlugin } from '#app/nuxt'
import { LazyButton, LazyButtonCircle, LazyButtonMore, LazyCountdown, LazyForm, LazyHeadline, LazyIcon, LazyImage, LazyInput, LazyInputButton, LazyLinkAction, LazyNumberControl, LazyPanel, LazySelect, LazySelectList, LazySwitch, LazyTable, LazyTextarea, LazyTimer, LazyTinyMceEditor } from '#components'
const lazyGlobalComponents = [
  ["Button", LazyButton],
["ButtonCircle", LazyButtonCircle],
["ButtonMore", LazyButtonMore],
["Countdown", LazyCountdown],
["Form", LazyForm],
["Headline", LazyHeadline],
["Icon", LazyIcon],
["Image", LazyImage],
["Input", LazyInput],
["InputButton", LazyInputButton],
["LinkAction", LazyLinkAction],
["NumberControl", LazyNumberControl],
["Panel", LazyPanel],
["Select", LazySelect],
["SelectList", LazySelectList],
["Switch", LazySwitch],
["Table", LazyTable],
["Textarea", LazyTextarea],
["Timer", LazyTimer],
["TinyMceEditor", LazyTinyMceEditor],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
